// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-recipes-by-author-js": () => import("./../../../src/templates/recipesByAuthor.js" /* webpackChunkName: "component---src-templates-recipes-by-author-js" */),
  "component---src-templates-recipes-by-tag-js": () => import("./../../../src/templates/recipesByTag.js" /* webpackChunkName: "component---src-templates-recipes-by-tag-js" */),
  "component---src-templates-recipes-grid-js": () => import("./../../../src/templates/recipesGrid.js" /* webpackChunkName: "component---src-templates-recipes-grid-js" */),
  "component---src-templates-recipes-list-js": () => import("./../../../src/templates/recipesList.js" /* webpackChunkName: "component---src-templates-recipes-list-js" */)
}

